import React from "react";

import logo from "../../assets/Jemmity_logo.png";
import logoIconOnly from "../../assets/apple-touch-icon.png";

import SideMenuItem from "../side-menu-item/side-menu-item.component";

const SideMenu = ({ selectedPage, iconsOnly }) => {
  // return (
  //   <div
  //     className="mt-2"
  //   >
  //     <div
  //       style={{
  //         fontWeight: "bold",
  //         fontSize: "24px",
  //         padding: 10,
  //       }}
  //     >
  //       <img src={logo} alt="JEMMITY" style={{ width: "80%" }} />
  //     </div>
  //     <SideMenuItem
  //       text="Home"
  //       url="/"
  //       iconClass="fa-solid fa-house"
  //       selected={selectedPage === "Home"}
  //     />
  //     <SideMenuItem
  //       text="Search Products"
  //       url="/search-products"
  //       iconClass="fas fa-search"
  //       selected={selectedPage === "Search Products"}
  //     />
  //     <SideMenuItem
  //       text="Import List"
  //       url="/imports"
  //       iconClass="fas fa-cart-arrow-down"
  //       selected={selectedPage === "Import List"}
  //     />
  //     <SideMenuItem
  //       text="My Products"
  //       url="/my-products"
  //       iconClass="fas fa-tags"
  //       selected={selectedPage === "My Products"}
  //     />
  //     <SideMenuItem
  //       text="My Orders"
  //       url="/orders"
  //       iconClass="fas fa-cart-plus"
  //       selected={selectedPage === "My Orders"}
  //     />
  //     <SideMenuItem
  //       text="Notifications"
  //       url="/notifications"
  //       iconClass="far fa-bell"
  //       selected={selectedPage === "Notifications"}
  //     />
  //     <SideMenuItem
  //       text="Help Centre"
  //       url="/help-centre"
  //       iconClass="far fa-question-circle"
  //       selected={selectedPage === "Help Centre"}
  //     />
  //     <SideMenuItem
  //       text="Settings"
  //       url="/settings"
  //       iconClass="fas fa-cog"
  //       selected={selectedPage === "Settings"}
  //     />
  //   </div>
  // );

  if (iconsOnly) {
    return (
      <div
        className="d-flex flex-column flex-shrink-0 bg-white"
        style={{ width: "4.5rem" }}
      >
        <a
          href="/"
          className="d-flex justify-content-center align-items-center mt-2 mb-2 text-black text-decoration-none"
          style={{ border: "1px solid red;" }}
        >
          <img
            src={logoIconOnly}
            alt="JEMMITY"
            style={{ width: "80%", border: "1px solid red;" }}
          />
        </a>
        <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
          <SideMenuItem
            text="Home"
            url="/"
            iconClass="fa-solid fa-house"
            selected={selectedPage === "Home"}
            iconsOnly
          />
          <SideMenuItem
            text="Search Products"
            url="/search-products"
            iconClass="fas fa-search"
            selected={selectedPage === "Search Products"}
            iconsOnly
          />
          <SideMenuItem
            text="Import List"
            url="/imports"
            iconClass="fas fa-cart-arrow-down"
            selected={selectedPage === "Import List"}
            iconsOnly
          />
          <SideMenuItem
            text="My Products"
            url="/my-products"
            iconClass="fas fa-tags"
            selected={selectedPage === "My Products"}
            iconsOnly
          />
          <SideMenuItem
            text="My Orders"
            url="/orders"
            iconClass="fas fa-cart-plus"
            selected={selectedPage === "My Orders"}
            iconsOnly
          />
          <SideMenuItem
            text="Notifications"
            url="/notifications"
            iconClass="far fa-bell"
            selected={selectedPage === "Notifications"}
            iconsOnly
          />
          <SideMenuItem
            text="Help Centre"
            url="/help-centre"
            iconClass="far fa-question-circle"
            selected={selectedPage === "Help Centre"}
            iconsOnly
          />
          <SideMenuItem
            text="Settings"
            url="/settings"
            iconClass="fas fa-cog"
            selected={selectedPage === "Settings"}
            iconsOnly
          />
        </ul>
      </div>
    );
  } else
    return (
      <div
        //className="d-flex flex-column flex-shrink-0 p-3 text-white bg-white ps-0"
        style={{
          width: "280px",
        }}
        //className="navbar navbar-expand-lg navbar-light bg-light"
      >
        <div
          // style={{
          //   width: "280px",
          // }}
        >
          <a
            href="/"
            className="p-3 d-flex align-items-center mb-3 mb-md-0 me-md-auto text-black text-decoration-none"
          >
            <span className="fs-4">
              <img src={logo} alt="JEMMITY" style={{ width: "80%" }} />
            </span>
          </a>
          <ul className="nav nav-pills flex-column mb-auto">
            <SideMenuItem
              text="Home"
              url="/"
              iconClass="fa-solid fa-house"
              selected={selectedPage === "Home"}
            />
            <SideMenuItem
              text="Search Products"
              url="/search-products"
              iconClass="fas fa-search"
              selected={selectedPage === "Search Products"}
            />
            <SideMenuItem
              text="Import List"
              url="/imports"
              iconClass="fas fa-cart-arrow-down"
              selected={selectedPage === "Import List"}
            />
            <SideMenuItem
              text="My Products"
              url="/my-products"
              iconClass="fas fa-tags"
              selected={selectedPage === "My Products"}
            />
            <SideMenuItem
              text="My Orders"
              url="/orders"
              iconClass="fas fa-cart-plus"
              selected={selectedPage === "My Orders"}
            />
            <SideMenuItem
              text="Notifications"
              url="/notifications"
              iconClass="far fa-bell"
              selected={selectedPage === "Notifications"}
            />
            <SideMenuItem
              text="Help Centre"
              url="/help-centre"
              iconClass="far fa-question-circle"
              selected={selectedPage === "Help Centre"}
            />
            <SideMenuItem
              text="Settings"
              url="/settings"
              iconClass="fas fa-cog"
              selected={selectedPage === "Settings"}
            />
          </ul>
        </div>
      </div>
    );
};

export default SideMenu;
